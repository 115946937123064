import Ajv from 'ajv';
import JSZip from 'jszip'
import lasHeader from 'las-header'
import deepmerge from 'deepmerge'
import { FetchSensorEquipmentListResponse } from './UploaderTypes'

interface AlertMessage {
    name: string;           // category
    message: any;
}
export interface FileRuleSummary {
    anyFileName: {[key: string]: Array<string>};
    basePath: string;
    errorMessages: Array<string>;
    alertMessages: Array<AlertMessage>;
    flightParts: {[key:string]: any};
    invalidFiles: Array<string>;
    uploadTypeLabel: string;
    validFiles: Array<string>;
}

interface FolderValidationHelperProps {
    sensorEquipment: FetchSensorEquipmentListResponse;
    sensorName: string;
    fileList: Array<File>;
    flightId: string;
    // sensorNumber: string;
    projectIdList: Array<string>;
    isPreexistingUpload: boolean;
    uploadTypes: Array<string>;
    maxChunkSizeMB: number;
    isSimultaneousImagery: boolean;
    processingTemplate: string;
}

export interface FolderValidationHelperReturn {
    // isValid: boolean;
    fileRuleSummary: FileRuleSummary;
    isMissingFlightplanCS: boolean;
}

export interface FMSLogAlertMessage {
    fmsLogFilenameListWithNoProjectId?: Array<string>;
    fmsLogFilenameListWithUnexpectedProjectId?: Array<string>;
    missingProjectIdList?: Array<string>;
}

const readFileAsync = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsText(file);
    })
}

const readFileAsyncAsArrayBuffer = (file: Blob): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    })
}

// Check if subsetArray contains all elements of parentArray
const checkSubsetArrContain = (parentArray: Array<string>, subsetArray: Array<string>) => {
    if(parentArray.length == 0) {
        return true
    }
    if(subsetArray.length) {
        return subsetArray.every((el) => {
            return parentArray.includes(el)
        })
    } else {
        return false
    }
}

const folderValidationHelper = async(props: FolderValidationHelperProps): Promise<FolderValidationHelperReturn> => {
    const { 
        sensorEquipment, sensorName, flightId, projectIdList, isPreexistingUpload, 
        maxChunkSizeMB, isSimultaneousImagery, processingTemplate
    } = props
    var { uploadTypes, fileList } = props

    let hasFlightPlan = false;
    let hasMinOneCS = false;
    let flightPlanParseError = false;

    let basePath = (fileList[0] as any).webkitRelativePath.split("/")[0]; //not safe
    // let fileMetadataSummaryList = [];
    let fileRuleSummary: FileRuleSummary = {
        basePath: basePath,
        flightParts: {},
        anyFileName: {},
        invalidFiles: [],
        validFiles: [],
        errorMessages: [],
        alertMessages: [],
        uploadTypeLabel: '',
    };

    //if Imagery, parsing sensor name for imagery type
    if (['LargeFormatImagery', 'MediumFormatImagery'].includes(uploadTypes[0])) {
        if (RegExp(/ A3 /, "g").test(sensorName)) {
            uploadTypes = ["A3_IMAGERY"];
        }
        else if (RegExp(/ DMC /, "g").test(sensorName)) {
            uploadTypes = ["DMC_IMAGERY"];
        }
        else {
            uploadTypes = ["IMAGERY"];
        }
    }

    let ajv = new Ajv({ allErrors: true, verbose: true });

    ajv.addKeyword('contiguousNumericRange', {
        validate: function (schema: any, data: any) {
            data = data.filter((item: string) => RegExp(/FL\d{6}.\d{3}/, "g").test(item))
            if (data.length === 0)
                return true;
            let isContiguous = data.every(function (item: string, index: number, array: Array<any>) {
                if (index > 0) {
                    let currentIntValue = parseInt(item.slice(-3))
                    let prevIntValue = parseInt(array[index - 1].slice(-3));
                    return currentIntValue === prevIntValue + 1;
                }
                return true;
            });
            return isContiguous;
        },
        errors: true
    });

    // console.log("FolderValidationHelper.parseFolder.uploadType: " + uploadTypes);
    let rulesSchema: {[key:string]: any} = {
        "fileTypes": {
            "pdf": {
                "type": "string", "pattern": "^FLIGHT_ID(_\\d{2})?\\.(pdf|PDF)$", "errorMessage": "Netsuite flight log report file name  (*.pdf) violates the naming standard, or doesn't match the FlightID:", "category": "lidarAndImagery", "uploadOrder": 1
            },
            "kmz": {
                "type": "string", "pattern": "^FLIGHT_ID(_\\d{2})?\\.(kmz|KMZ)$", "errorMessage": "LiDAR coverage overview file name  (*.kmz) violates the naming standard, or doesn't match the FlightID:", "category": "lidar", "uploadOrder": 2
            },
            "kmz_flightplan": {
                "type": "string", "pattern": "^(?!.*\\/)PRJ.*\.(KMZ|kmz)$", "errorMessage": "LiDAR flight plan file name (*.kmz) violates the naming standard and these must be in the root folder:", "category": "lidar", "uploadOrder": 16
            },
            "csv": {
                "type": "string", "pattern": "^FLIGHT_ID(_\\d{2})?\\.(csv|CSV)$", "errorMessage": "EXIF log file name  (*.csv) violates the naming standard, or doesn't match the FlightID:", "category": "imagery", "uploadOrder": 3
            },
            "xls": {
                "type": "string", "pattern": "^FLIGHT_ID(_\\d{2})?\\.(xls|XLS)$", "errorMessage": "Excel file name  (*.xls) violates the naming standard, or doesn't match the FlightID:", "category": "imagery", "uploadOrder": 16
            },
            // "gps": {
            //     "type": "string", "pattern": "^FLIGHT_ID(_\\d{2})?\.(\\d{3}|\\d{2}n|\\d{2}g|\\d{1}n|\\d{1}g|n|g)$", "errorMessage": "GPS file name violates the naming standard, or doesn't match the FlightID:", "category": "lidarAndImagery", "uploadOrder": 3
            // },
            "gps": {
                "type": "string", "pattern": "^FLIGHT_ID(_\\d{2})*\.(\\d{3}|\\d{2}n|\\d{2}g|\\d{1}n|\\d{1}g|n|g)$", "errorMessage": "GPS file name violates the naming standard, or doesn't match the FlightID:", "category": "lidarAndImagery", "uploadOrder": 4
            },
            "pos": {
                "type": "string", "pattern": "^FLIGHT_ID(_\\d{2})*\.(pos|POS)$", "errorMessage": "GPS file name violates the naming standard, or doesn't match the FlightID:", "category": "lidarAndImagery", "uploadOrder": 5
            },
            "gpso": {
                "type": "string", "pattern": "^FLIGHT_ID(_\\d{2})?\\.(gpso|GPSO)$", "errorMessage": "GNSS / INS file (*.GPSO) name violates the naming standard, or doesn't match the FlightID:", "category": "imagery", "uploadOrder": 6
            },
            "gpsn": {
                "type": "string", "pattern": "^FLIGHT_ID(_\\d{2})?\\.(gpsn|GPSN)$", "errorMessage": "GNSS / INS file (*.GPSN) name violates the naming standard, or doesn't match the FlightID:", "category": "imagery", "uploadOrder": 7
            },
            "range": {
                "type": "string", "pattern": "^FLIGHT_ID(_\\d{2})?\.range$", "errorMessage": "Range file name violates the naming standard, or doesn't match the FlightID:", "category": "lidar", "uploadOrder": 8
            },
            "jpg": {
                "type": "string", "pattern": "^FLIGHT_ID(_\\d{2})?\\.(jpg|JPG)$", "errorMessage": "Imagery coverage overview file name (*.jpg) violates the naming standard, or doesn't match the FlightID:", "category": "imagery", "uploadOrder": 9
            },
            "jgw": {
                "type": "string", "pattern": "^FLIGHT_ID(_\\d{2})?\\.(jgw|JGW)$", "errorMessage": "Imagery coverage overview file name (*.jgw) violates the naming standard, or doesn't match the FlightID:", "category": "imagery", "uploadOrder": 10
            },
            "iiq": {
                "type": "string", "pattern": "^(.*/)*(\\w|_|-|\\.)+\\.(IIQ|iiq)$", "errorMessage": "Imagery file name (*.iiq) violates the naming standard:", "category": "imagery", "uploadOrder": 11
            },
            "rawimage": {
                "type": "string", "pattern": "^FLIGHT_ID(_\\d{2})?\\.(rawimage|RAWIMAGE)$", "errorMessage": "Raw image file name (*.rawimage) violates the naming standard, or doesn't match the FlightID:", "category": "imagery", "uploadOrder": 12
            },
            "bin": {
                "type": "string", "pattern": "^(.*/)*(\\w|_|-|\\.)+\\.(bin|BIN)$", "errorMessage": "Imagery file (*.BIN) name violates the naming standard:", "category": "imagery", "uploadOrder": 13
            },
            "dat": {
                "type": "string", "pattern": "^.+\\.(dat|DAT)$", "errorMessage": "Imagery file (*.DAT) name violates the naming standard:", "category": "imagery", "uploadOrder": 14
            },
            "log": {
                "type": "string", "pattern": "^FMS_Flight_Log_.+\\.log$", "errorMessage": "LiDAR FMS Nav log file name (*.log) violates the naming standard and these must be in the root folder:", "category": "lidar", "uploadOrder": 17
            },
            // "laz": {
            //     "type": "string", "pattern": "^\\d{3}_\\d{6}_\\d{4}-\\d{5}\\.(laz|LAZ)$", "errorMessage": "Terrain Mapper LiDAR file name  (*.laz) violates the naming standard, or doesn't match the FlightID:", 
            // },
            "laz": {
                "type": "string", "pattern": "\\.(laz|LAZ)$", "errorMessage": "Terrain Mapper LiDAR file name (*.laz) violates the naming standard:", 
            },
            "sol": {
                "type": "string", "pattern": "\\.(sol|SOL)$", "errorMessage": "Terrain Mapper SOL file name (*.sol) violates the naming standard:", 
            },
            "tmGpsDat": {
                "type": "string", "pattern": ".*/MM1/.+_GnssImu\\.(dat|DAT|dat\\.sha1)$", "errorMessage": "Terrain Mapper GPS file name (*.dat) violates the naming standard:", 
            },
            "tmGpsRnv": {
                "type": "string", "pattern": ".*/MM1/.+_GnssImuRts\\.(rnv|RNV|rnv\\.sha1)$", "errorMessage": "Terrain Mapper GPS file name (*.rnv) violates the naming standard:",
            },
            "tmGpsTimeSync": {
                "type": "string", "pattern": ".*/MM1/TimeSync\\.(raw|raw\\.sha1)$", "errorMessage": "Terrain Mapper TimeSync.raw file violates the naming standard:", 
            },
            "tmRaw": {
                "type": "string", "pattern": ".(raw|RAW)$", "errorMessage": "Terrain Mapper Lidar raw (*.raw) violates the naming standard:", 
            },
            "tmRawSha1": {
                "type": "string", "pattern": ".(raw.sha1|RAW.SHA1)$", "errorMessage": "Terrain Mapper Lidar raw sha1 (*.raw.sha1) violates the naming standard:", 
            },
            "tmDat": {
                "type": "string", "pattern": ".(dat|DAT)$", "errorMessage": "Terrain Mapper Lidar dat (*.dat) violates the naming standard:", 
            },
            "tmDatSha1": {
                "type": "string", "pattern": ".(dat.sha1|DAT.SHA1)$", "errorMessage": "Terrain Mapper Lidar dat sha1 (*.dat.sha1) violates the naming standard:", 
            },
            "tmCsv": {
                "type": "string", "pattern": ".*\\.(csv|CSV)$", "errorMessage": "Terrain Mapper Lidar file (*.csv) violates the naming standard:", 
            },
            "tmCsvSha1": {
                "type": "string", "pattern": ".*\\.(csv.sha1|CSV.SHA1)$", "errorMessage": "Terrain Mapper Lidar file (*.csv.sha1) violates the naming standard:", 
            },
            "tmFpd3": {
                "type": "string", "pattern": ".*\\.(fpd3|FPD3)$", "errorMessage": "Terrain Mapper Lidar file (*.fpd3) violates the naming standard:", 
            },
            "tmFpd3Sha1": {
                "type": "string", "pattern": ".*\\.(fpd3.sha1|FPD3.SHA1)$", "errorMessage": "Terrain Mapper Lidar file (*.fpd3.sha1) violates the naming standard:", 
            },
            "tmHex": {
                "type": "string", "pattern": ".*\\.(hex|HEX)$", "errorMessage": "Terrain Mapper Lidar file (*.hex) violates the naming standard:", 
            },
            "tmHexSha1": {
                "type": "string", "pattern": ".*\\.(hex.sha1|HEX.SHA1)$", "errorMessage": "Terrain Mapper Lidar file (*.hex.sha1) violates the naming standard:", 
            },
            "tmTxt": {
                "type": "string", "pattern": ".*\\.(txt|TXT)$", "errorMessage": "Terrain Mapper Lidar file (*.txt) violates the naming standard:", 
            },
            "tmTxtSha1": {
                "type": "string", "pattern": ".*\\.(txt.sha1|TXT.sha1|TXT.SHA1)$", "errorMessage": "Terrain Mapper Lidar file (*.txt.sha1) violates the naming standard:", 
            },
            "tmBin": {
                "type": "string", "pattern": ".*\\.(bin|BIN)$", "errorMessage": "Terrain Mapper Lidar file (*.bin) violates the naming standard:", 
            },
            "tmBinSha1": {
                "type": "string", "pattern": ".*\\.(bin.sha1|BIN.SHA1)$", "errorMessage": "Terrain Mapper Lidar file (*.bin.sha1) violates the naming standard:", 
            },
            "tmEvtx": {
                "type": "string", "pattern": ".*\\.(evtx|EVTX)$", "errorMessage": "Terrain Mapper Lidar file (*.evtx) violates the naming standard:", 
            },
            "tmEvtxSha1": {
                "type": "string", "pattern": ".*\\.(evtx.sha1|EVTX.SHA1)$", "errorMessage": "Terrain Mapper Lidar file (*.evtx.sha1) violates the naming standard:", 
            },
            "tmLog": {
                "type": "string", "pattern": ".*\\.(log|LOG)$", "errorMessage": "Terrain Mapper Lidar file (*.log) violates the naming standard:", 
            },
            "tmLogSha1": {
                "type": "string", "pattern": ".*\\.(log.sha1|LOG.SHA1)$", "errorMessage": "Terrain Mapper Lidar file (*.log.sha1) violates the naming standard:", 
            },
            "tmXml": {
                "type": "string", "pattern": ".*\\.(xml|XML)$", "errorMessage": "Terrain Mapper Lidar file (*.xml) violates the naming standard:", 
            },
            "tmXmlSha1": {
                "type": "string", "pattern": ".*\\.(xml.sha1|XML.sha1|XML.SHA1)$", "errorMessage": "Terrain Mapper Lidar file (*.xml.sha1) violates the naming standard:", 
            },
            "tmRnv": {
                "type": "string", "pattern": ".*\\.(rnv|RNV)$", "errorMessage": "Terrain Mapper Lidar file (*.rnv) violates the naming standard:", 
            },
            "tmRnvSha1": {
                "type": "string", "pattern": ".*\\.(rnv.sha1|RNV.SHA1)$", "errorMessage": "Terrain Mapper Lidar file (*.rnv.sha1) violates the naming standard:", 
            },
            "tmTimeSyncRaw": {
                "type": "string", "pattern": ".*\\TimeSync\\.(raw|RAW)$", "errorMessage": "Terrain Mapper Lidar file (*.TimeSync.raw) violates the naming standard:", 
            },
            "tmTimeSyncRawSha1": {
                "type": "string", "pattern": ".*\\TimeSync\\.(raw.sha1|RAW.SHA1)$", "errorMessage": "Terrain Mapper Lidar file (*.TimeSync.raw.sha1) violates the naming standard:", 
            },
            "tmThumbnails": {
                "type": "string", "pattern": "Thumbnails/", "errorMessage": "Terrain Mapper Imagery files (/Thumbnails/...)violates the naming standard:", 
            },
            "tmDarkFrames": {
                "type": "string", "pattern": "DarkFrames/", "errorMessage": "Terrain Mapper Imagery files (/DarkFrames/...) violates the naming standard:", 
            },
            "tmImageryReturnsDat": {
                "type": "string", "pattern": "MM\\d{1,2}\/[\\w\\d\\s\-_\/]+_Returns.(dat|DAT)$", "errorMessage": "Terrain Mapper raw imagery return dat (*_Returns.dat) violates the naming standard:", 
            },
            "tmImageryReturnsDatSha1": {
                "type": "string", "pattern": "MM\\d{1,2}\/[\\w\\d\\s\-_\/]+_Returns.(dat.sha1|DAT.SHA1)$", "errorMessage": "Terrain Mapper raw imagery return dat sha1 (*_Returns.dat.sha1) violates the naming standard:", 
            },
            "allFilesImageryTarget": {
                "type": "string", "pattern": "^.+$", "errorMessage": "", "category": "imagery", "uploadOrder": 15
            },
            "allFilesTerrainMapperRAW": {
                "type": "string", "pattern": "^.+$", "errorMessage": "", "category": "terrain_mapper"
            }
        },
        "LiDAR": {
            "labels": ["LiDAR"],
            "type": "object",
            "properties": {
                "basePath": { "type": "string", "pattern": "^FLIGHT_ID_\\d{3}$", "errorMessage": "The base folder name violates the naming standard, or doesn't match the FlightID and Sensor Number:" },
                "flightParts": {
                    "type": "array",
                    "minItems": 1,
                    "errorMessage": "A minimum of at least one set of Flight GPS and Range files is required, for each Flight segment",
                    "items": {
                        "type": "object",
                        "properties": {
                            //NOTE: this uses a negative lookahead to exclude pdf and range files... may need something like this in the imagery rules too
                            "flightPart": { "type": "string", "pattern": "^FL\\d{6}(_\\d{2})?(?!.(pdf|\\d{3}|\\d{2}n|\\d{2}g|\\d{1}n|\\d{1}g|n|g))$", "errorMessage": "Flight part name violates the naming standard:" },
                            // "flightPart": { "type": "string", "pattern": "^FL\\d{6}(_\\d{2})?$", "errorMessage": "Flight part name violates the naming standard:" },
                            "range": {
                                "type": "array",
                                "minItems": 0,
                                "errorMessage": "A minimum of at least one set of Range files is required for each Flight segment",
                                "items": { "$ref": "#/fileTypes/range" },
                            },
                            "kmz": {
                                "type": "array",
                                "minItems": 0,
                                "errorMessage": "A minimum of at least one set of LiDAR coverage files (*.kmz) is required for each Flight segment",
                                "items": { "$ref": "#/fileTypes/kmz" },
                            },
                        },
                        "required": ["range"]
                    }
                },
                "anyFileName": {
                    "type": "object",
                    "properties": {
                        // "gps": {
                        //     "type": "array",
                        //     "contiguousNumericRange": true,
                        //     "errorMessage": "There are gaps in the GPS files",
                        //     "items": { "$ref": "#/fileTypes/gps" },
                        // },
                        "gps": {
                            "type": "array",
                            "minItems": 1,
                            "contiguousNumericRange": true,
                            "errorMessage": "A minimum of at least one GPS file is required, there can be no gaps in the GPS file sequence and confirm correct folder naming if using an Eclipse sensor.",
                            "items": { "$ref": "#/fileTypes/gps" },
                        },
                        "pdf": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "A minimum of at least one Netsuite Flight Log Report (*.pdf) is required for each Flight segment",
                            "items": { "$ref": "#/fileTypes/pdf" },
                        },
                        "kmz_flightplan": {
                            "type": "array",
                            "minItems": 1,
                            "errorMessage": "A minimum of at least one flight plan (*.kmz) is required",
                            "items": { "$ref": "#/fileTypes/kmz_flightplan" }
                        },
                        "log": {
                            "type": "array",
                            "minItems": 1,
                            "errorMessage": "A minimum of at least one FMS Nav log files (FMS_Flight_Log_*.log) is required for each Flight segment",
                            "items": { "$ref": "#/fileTypes/log" },
                        }
                    },
                    "required": ["gps","kmz_flightplan","log"]
                }
            }
        },
        "IMAGERY": {
            "labels": ["Medium Format Imagery"],
            "type": "object",
            "properties": {
                "basePath": { "type": "string", "pattern": "^FLIGHT_ID_\\d{3}$", "errorMessage": "The base folder name violates the naming standard, or doesn't match the FlightID and Sensor Number:" },
                "flightParts": {
                    "type": "array",
                    "minItems": 1,
                    "errorMessage": "A minimum of at least one set of Flight GPS and Imagery files is required, for each Flight segment",
                    "items": {
                        "type": "object",
                        "properties": {
                            "flightPart": { "type": "string", "pattern": "^FL\\d{6}(_\\d{2})?$", "errorMessage": "Flight part name violates the naming standard:" },
                            // "gps": {
                            //     "type": "array",
                            //     "minItems": 1,
                            //     "errorMessage": "A minimum of at least one set of GPS files is required for each Flight segment",
                            //     "items": { "$ref": "#/fileTypes/gps" },
                            // },
                            "gps": {
                                "type": "array",
                                "contiguousNumericRange": true,
                                "errorMessage": "There are gaps in the GPS files",
                                "items": { "$ref": "#/fileTypes/gps" },
                            },
                            "csv": {
                                "type": "array",
                                "minItems": 0,
                                "errorMessage": "A minimum of at least one set of EXIF logs (*.csv) files is required for each Flight segment",
                                "items": { "$ref": "#/fileTypes/csv" },
                            },
                            "xls": {
                                "type": "array",
                                "minItems": 1,
                                "errorMessage": "A minimum of at least one set of Excel logs (*.xls) files is required for each Flight segment",
                                "items": { "$ref": "#/fileTypes/xls" },
                            },
                            // "log": {
                            //     "type": "array",
                            //     "minItems": 1,
                            //     "errorMessage": "A minimum of at least one Log (*.log) files is required for each Flight segment",
                            //     "items": { "$ref": "#/fileTypes/csv" },
                            // },
                            // "jpg": {
                            //     "type": "array",
                            //     "minItems": 0,
                            //     "errorMessage": "A minimum of at least one image coverage overview jpg is required for each Flight segment",
                            //     "items": { "$ref": "#/fileTypes/jpg" },
                            // },
                            // "jgw": {
                            //     "type": "array",
                            //     "minItems": 0,
                            //     "errorMessage": "A minimum of at least one image coverage overview world file is required for each Flight segment",
                            //     "items": { "$ref": "#/fileTypes/jgw" },
                            // },
                            "pdf": {
                                "type": "array",
                                "minItems": 0,
                                "errorMessage": "A minimum of at least one Netsuite Flight Log Report (*.pdf) is required for each Flight segment",
                                "items": { "$ref": "#/fileTypes/pdf" },
                            }
                        },
                        "required": ["gps", "csv"]//, "iiq"]//, "jpg", "jgw", "pdf" ]
                    }
                },
                "anyFileName": {
                    "type": "object",
                    "properties": {
                        "iiq": {
                            "type": "array",
                            "minItems": 1,
                            "errorMessage": "A minimum of at least one set of Imagery (*.iiq) files is required",
                            "items": { "$ref": "#/fileTypes/iiq" },
                        }
                    },
                    //"required": ["iiq"]
                    "required": []//iiq made optional per LS-362
                }
            }
        },
        "A3_IMAGERY": {
            "labels": ["A3 Edge Imagery"],
            "type": "object",
            "properties": {
                "basePath": { "type": "string", "pattern": "^FLIGHT_ID_\\d{3}$", "errorMessage": "The base folder name violates the naming standard, or doesn't match the FlightID and Sensor Number:" },
                "flightParts": {
                    "type": "array",
                    "minItems": 1,
                    "errorMessage": "A minimum of at least one set of Flight GPS and Imagery files is required, for each Flight segment",
                    "items": {
                        "type": "object",
                        "properties": {
                            "flightPart": { "type": "string", "pattern": "^FL\\d{6}(_\\d{2})?$", "errorMessage": "Flight part name violates the naming standard:" },
                            "gpso": {
                                "type": "array",
                                "minItems": 1,
                                "errorMessage": "A minimum of at least one set of GNSS / INS files (*.GPSO) is required for each Flight segment",
                                "items": { "$ref": "#/fileTypes/gpso" },
                            },
                            "gpsn": {
                                "type": "array",
                                "minItems": 1,
                                "errorMessage": "A minimum of at least one set of GNSS / INS files (*.GPSN) is required for each Flight segment",
                                "items": { "$ref": "#/fileTypes/gpsn" },
                            },
                            "pdf": {
                                "type": "array",
                                "minItems": 1,
                                "errorMessage": "A minimum of at least one Netsuite Flight Log Report (*.pdf) is required for each Flight segment",
                                "items": { "$ref": "#/fileTypes/pdf" },
                            },
                            "xls": {
                                "type": "array",
                                "minItems": 1,
                                "errorMessage": "A minimum of at least one set of Excel logs (*.xls) files is required for each Flight segment",
                                "items": { "$ref": "#/fileTypes/xls" },
                            }
                        },
                        "required": ["gpso", "gpsn"]
                    }
                },
                "anyFileName": {
                    "type": "object",
                    "properties": {
                        "bin": {
                            "type": "array",
                            "minItems": 1,
                            "errorMessage": "A minimum of at least one Image file (*.bin) is required",
                            "items": { "$ref": "#/fileTypes/bin" },
                        },
                    },
                    // "required": ["bin"]
                }
            }
        },
        "DMC_IMAGERY": {
            "labels": ["DMC 1 Imagery"],
            "type": "object",
            "properties": {
                "basePath": { "type": "string", "pattern": "^FLIGHT_ID_\\d{3}$", "errorMessage": "The base folder name violates the naming standard, or doesn't match the FlightID and Sensor Number:" },
                "flightParts": {
                    "type": "array",
                    "minItems": 1,
                    "errorMessage": "A minimum of at least one set of Flight GPS and Imagery files is required, for each Flight segment",
                    "items": {
                        "type": "object",
                        "properties": {
                            "flightPart": { "type": "string", "pattern": "^FL\\d{6}(_\\d{2})?$", "errorMessage": "Flight part name violates the naming standard:" },
                            // "gps": {
                            //     "type": "array",
                            //     "minItems": 1,
                            //     "errorMessage": "A minimum of at least one set of GPS files is required for each Flight segment",
                            //     "items": { "$ref": "#/fileTypes/gps" },
                            // },
                            "gps": {
                                "type": "array",
                                "contiguousNumericRange": true,
                                "errorMessage": "There are gaps in the GPS files",
                                "items": { "$ref": "#/fileTypes/gps" },
                            },
                            "pdf": {
                                "type": "array",
                                "minItems": 1,
                                "errorMessage": "A minimum of at least one Netsuite Flight Log Report (*.pdf) is required for each Flight segment",
                                "items": { "$ref": "#/fileTypes/pdf" },
                            },
                            "xls": {
                                "type": "array",
                                "minItems": 1,
                                "errorMessage": "A minimum of at least one set of Excel logs (*.xls) files is required for each Flight segment",
                                "items": { "$ref": "#/fileTypes/xls" },
                            }
                        },
                        "required": ["gps"]
                    }
                },
                "anyFileName": {
                    "type": "object",
                    "properties": {
                        "dat": {
                            "type": "array",
                            "minItems": 1,
                            "errorMessage": "A minimum of at least one Image file (*.dat) is required",
                            "items": { "$ref": "#/fileTypes/dat" },
                        },
                        "allFilesImageryTarget": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/allFilesImageryTarget" },
                        },
                    },
                    // "required": ["dat"]
                }
            }
        },
        "TerrainMapperLAZ": {
            "labels": ["Terrain Mapper LAZ"],
            "type": "object",
            "properties": {
                "basePath": { "type": "string", "pattern": "^FLIGHT_ID_\\d{3}$", "errorMessage": "The base folder name violates the naming standard, or doesn't match the FlightID and Sensor Number:" },
                // "flightParts": {
                //     "type": "array",
                //     "minItems": 1,
                //     "errorMessage": "A minimum of at least one set of Flight LAZ is required, for each Flight segment",
                //     "items": {
                //         "type": "object",
                //         "properties": {
                //             "flightPart": { "type": "string", "pattern": "^FL\\d{6}(_\\d{2})?$", "errorMessage": "Flight part name violates the naming standard:" },
                //             "gps": {
                //                 "type": "array",
                //                 "contiguousNumericRange": true,
                //                 "errorMessage": "There are gaps in the GPS files",
                //                 "items": { "$ref": "#/fileTypes/gps" },
                //             },
                //             "pdf": {
                //                 "type": "array",
                //                 "minItems": 1,
                //                 "errorMessage": "A minimum of at least one Netsuite Flight Log Report (*.pdf) is required for each Flight segment",
                //                 "items": { "$ref": "#/fileTypes/pdf" },
                //             },
                //             "xls": {
                //                 "type": "array",
                //                 "minItems": 1,
                //                 "errorMessage": "A minimum of at least one set of Excel logs (*.xls) files is required for each Flight segment",
                //                 "items": { "$ref": "#/fileTypes/xls" },
                //             }
                //         },
                //         "required": ["gps"]
                //     }
                // },
                "anyFileName": {
                    "type": "object",
                    "properties": {
                        "laz": {
                            "type": "array",
                            "minItems": 1,
                            "errorMessage": "A minimum of at least one LAZ file (*.laz) is required",
                            "items": { "$ref": "#/fileTypes/laz" },
                        },
                    },
                    "required": ["laz"]
                }
            }
        },
        "TerrainMapperSOL": {
            "labels": ["Terrain Mapper SOL"],
            "type": "object",
            "properties": {
                "basePath": { "type": "string", "pattern": "^FLIGHT_ID_\\d{3}$", "errorMessage": "The base folder name violates the naming standard, or doesn't match the FlightID and Sensor Number:" },
                // "flightParts": {
                //     "type": "array",
                //     "minItems": 1,
                //     "errorMessage": "A minimum of at least one set of Flight LAZ is required, for each Flight segment",
                //     "items": {
                //         "type": "object",
                //         "properties": {
                //             "flightPart": { "type": "string", "pattern": "^FL\\d{6}(_\\d{2})?$", "errorMessage": "Flight part name violates the naming standard:" },
                //             "gps": {
                //                 "type": "array",
                //                 "contiguousNumericRange": true,
                //                 "errorMessage": "There are gaps in the GPS files",
                //                 "items": { "$ref": "#/fileTypes/gps" },
                //             },
                //             "pdf": {
                //                 "type": "array",
                //                 "minItems": 1,
                //                 "errorMessage": "A minimum of at least one Netsuite Flight Log Report (*.pdf) is required for each Flight segment",
                //                 "items": { "$ref": "#/fileTypes/pdf" },
                //             },
                //             "xls": {
                //                 "type": "array",
                //                 "minItems": 1,
                //                 "errorMessage": "A minimum of at least one set of Excel logs (*.xls) files is required for each Flight segment",
                //                 "items": { "$ref": "#/fileTypes/xls" },
                //             }
                //         },
                //         "required": ["gps"]
                //     }
                // },
                "anyFileName": {
                    "type": "object",
                    "properties": {
                        "sol": {
                            "type": "array",
                            "minItems": 1,
                            "errorMessage": "A minimum of at least one SOL file (*.sol) is required",
                            "items": { "$ref": "#/fileTypes/sol" },
                        },
                    },
                    "required": ["sol"]
                }
            }
        },
        "TerrainMapperGPS": {
            "labels": ["Terrain Mapper GPS"],
            "type": "object",
            "properties": {
                "basePath": { "type": "string", "pattern": "^FLIGHT_ID_\\d{3}$", "errorMessage": "The base folder name violates the naming standard, or doesn't match the FlightID and Sensor Number:" },
                "anyFileName": {
                    "type": "object",
                    "properties": {
                        "tmGpsDat": {
                            "type": "array",
                            "minItems": 1,
                            "errorMessage": "A minimum of at least one terrain mapper GPS file (*.dat) is required",
                            "items": { "$ref": "#/fileTypes/tmGpsDat" },
                        },
                        // "tmGpsRnv": {
                        //     "type": "array",
                        //     "minItems": 1,
                        //     "errorMessage": "A minimum of at least one terrain mapper GPS file (*.rnv) is required",
                        //     "items": { "$ref": "#/fileTypes/tmGpsRnv" },
                        // },
                        // "tmGpsTimeSync": {
                        //     "type": "array",
                        //     "minItems": 1,
                        //     "errorMessage": "A minimum of at least one terrain mapper TimeSync.raw file is required",
                        //     "items": { "$ref": "#/fileTypes/tmGpsTimeSync" },
                        // },
                    },
                    // "required": ["tmGpsDat","tmGpsRnv"]
                    "required": ["tmGpsDat"]
                }
            }
        },
        "TerrainMapperRAW": {
            "labels": ["Terrain Mapper RAW"],
            "type": "object",
            "properties": {
                "basePath": { "type": "string", "pattern": "^FLIGHT_ID_\\d{3}$", "errorMessage": "The base folder name violates the naming standard, or doesn't match the FlightID and Sensor Number:" },
                "anyFileName": {
                    "type": "object",
                    "properties": {
                        // "tmGpsDat": {
                        //     "type": "array",
                        //     "maxItems": 1,
                        //     "errorMessage": "A maximum of one terrain mapper GPS file (*.dat) will be uploaded",
                        //     "items": { "$ref": "#/fileTypes/tmGpsDat" },
                        // },
                        "allFilesTerrainMapperRAW": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/allFilesTerrainMapperRAW" },
                        },
                    },
                    "required": []
                }
            }
        },
        "TerrainMapperLIDAR": {
            "labels": ["Terrain Mapper RAW Lidar"],
            "type": "object",
            "properties": {
                "basePath": { "type": "string", "pattern": "^FLIGHT_ID_\\d{3}$", "errorMessage": "The base folder name violates the naming standard, or doesn't match the FlightID and Sensor Number:" },
                "anyFileName": {
                    "type": "object",
                    "properties": {
                        // "allFilesTerrainMapperRAW": {
                        //     "type": "array",
                        //     "minItems": 0,
                        //     "errorMessage": "",
                        //     "items": { "$ref": "#/fileTypes/allFilesTerrainMapperRAW" },
                        // },
                        "tmCsv": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmCsv" },
                        },
                        "tmCsvSha1": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmCsvSha1" },
                        },
                        "tmFpd3": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmFpd3" },
                        },
                        "tmFpd3Sha1": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmFpd3Sha1" },
                        },
                        "tmHex": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmHex" },
                        },
                        "tmHexSha1": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmHexSha1" },
                        },
                        "tmTxt": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmTxt" },
                        },
                        "tmTxtSha1": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmTxtSha1" },
                        },
                        "tmBin": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmBin" },
                        },
                        "tmBinSha1": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmBinSha1" },
                        },
                        "tmEvtx": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmEvtx" },
                        },
                        "tmEvtxSha1": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmEvtxSha1" },
                        },
                        "tmLog": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmLog" },
                        },
                        "tmLogSha1": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmLogSha1" },
                        },
                        "tmXml": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmXml" },
                        },
                        "tmXmlSha1": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmXmlSha1" },
                        },
                        "tmDat": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmDat" },
                        },
                        "tmDatSha1": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmDatSha1" },
                        },
                        "tmRnv": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmRnv" },
                        },
                        "tmRnvSha1": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmRnvSha1" },
                        },
                        "tmTimeSyncRaw": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmTimeSyncRaw" },
                        },
                        "tmTimeSyncRawSha1": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/tmTimeSyncRawSha1" },
                        },
                    },
                    "required": []
                }
            }
        },
        "TerrainMapperImagery": {
            "labels": ["Terrain Mapper RAW Imagery"],
            "type": "object",
            "properties": {
                "basePath": { "type": "string", "pattern": "^FLIGHT_ID_\\d{3}$", "errorMessage": "The base folder name violates the naming standard, or doesn't match the FlightID and Sensor Number:" },
                "anyFileName": {
                    "type": "object",
                    "properties": {
                        "allFilesTerrainMapperRAW": {
                            "type": "array",
                            "minItems": 0,
                            "errorMessage": "",
                            "items": { "$ref": "#/fileTypes/allFilesTerrainMapperRAW" },
                        },
                    },
                    "required": []
                }
            }
        }
    };

    //anotehr specifc rule variation... if simultaneous imagery, GPS is optional
    if (isSimultaneousImagery) {
        let index = rulesSchema["IMAGERY"].properties.flightParts.items.required.indexOf("gps");

        if (index > -1) {
            rulesSchema["IMAGERY"].properties.flightParts.items.required.splice(index, 1);
        }

    }

    //FFS more specific rules, see LS-761
    if (RegExp(/ Vexcell /, "g").test(sensorName)) {
        delete rulesSchema["IMAGERY"].properties.flightParts.items.properties["csv"];
    }

    //Urghhhh hate doing this, but am told I have to... in the case of a specific sensor swap out the required gps file type/rule
    //ECLIPSE 362 specific rules should be added above.... not a monkey hack as below
    if (basePath.slice(-3) === "362") {
        // console.log('...ruleSchema',rulesSchema);    
        //Eclipse 362 LiDAR adjustments 
        delete rulesSchema["LiDAR"].properties.flightParts.items.properties["gps"];
        delete rulesSchema["LiDAR"].properties.flightParts.items.properties["pdf"];
        rulesSchema["LiDAR"].properties.flightParts.items.properties["pos"] = {
            "type": "array",
            "minItems": 1,
            "maxItems": 1,
            "errorMessage": "Sensor 362 requires *.pos GPS files",
            "items": { "$ref": "#/fileTypes/pos" },
        }
        rulesSchema["LiDAR"].properties.flightParts.items.required = ["pos", "range"];
        rulesSchema["LiDAR"].properties.anyFileName.required = [];
        delete rulesSchema["LiDAR"].properties.anyFileName.properties.gps;
        // delete rulesSchema["LIDAR"].properties.anyFileName.properties.gps.contiguousNumericRange;
        // rulesSchema["LIDAR"].properties.anyFileName = ["pdf"];
        // rulesSchema["LIDAR"].properties.anyFileName.properties.gps.items =  { "$ref": "#/fileTypes/pos" };
        // rulesSchema["LIDAR"].properties.anyFileName.required = ["pos"];

        //Eclipse 362 IMAGERY adjustments
        delete rulesSchema["IMAGERY"].properties.flightParts.items.properties["gps"];
        delete rulesSchema["IMAGERY"].properties.flightParts.items.properties["iiq"];
        delete rulesSchema["IMAGERY"].properties.flightParts.items.properties["pdf"];
        rulesSchema["IMAGERY"].properties.flightParts.items.properties["pos"] = {
            "type": "array",
            "minItems": 1,
            "errorMessage": "Sensor 362 requires *.pos GPS files",
            "items": { "$ref": "#/fileTypes/pos" },
        }
        rulesSchema["IMAGERY"].properties.flightParts.items.properties["rawimage"] = {
            "type": "array",
            "minItems": 1,
            "errorMessage": "Sensor 362 requires *.rawimage files",
            "items": { "$ref": "#/fileTypes/rawimage" },
        }
        rulesSchema["IMAGERY"].properties.anyFileName.properties["pdf"] = {
            "type": "array",
            "minItems": 0,
            "errorMessage": "A minimum of at least one Netsuite Flight Log Report (*.pdf) is required for each Flight segment",
            "items": { "$ref": "#/fileTypes/pdf" },
        }
        rulesSchema["IMAGERY"].properties.flightParts.items.required = ["pos", "rawimage"];
        // rulesSchema["IMAGERY"].properties.flightParts.items.required = [];
        rulesSchema["IMAGERY"].properties.anyFileName.required = [];
        delete rulesSchema["IMAGERY"].properties.anyFileName.properties.gps;
        delete rulesSchema["IMAGERY"].properties.anyFileName.properties.iiq;
    }

    Object.keys(rulesSchema.fileTypes).map(item => {
        rulesSchema.fileTypes[item].pattern = rulesSchema.fileTypes[item].pattern.replace('FLIGHT_ID', flightId)
    })

    let schema: {[key:string]: any} = { "fileTypes": rulesSchema["fileTypes"] };
    uploadTypes.forEach(function (item) {
        rulesSchema[item].properties.basePath.pattern = rulesSchema[item].properties.basePath.pattern.replace('FLIGHT_ID', flightId)
        schema = deepmerge(schema, rulesSchema[item])
        if(schema.properties.flightParts) {
            schema.properties.flightParts.items.required = (schema.properties.flightParts.items.required as Array<any>).filter((item, index, self) => self.indexOf(item) === index)
        }
        if (schema.properties.anyFileName && schema.properties.anyFileName.required) {
            schema.properties.anyFileName.required = (schema.properties.anyFileName.required as Array<any>).filter((item, index, self) => self.indexOf(item) === index);
        }
    })
    if (schema.labels.length === 1)
        fileRuleSummary["uploadTypeLabel"] = schema.labels.pop();
    else if (schema.labels.length === 2)
        fileRuleSummary["uploadTypeLabel"] = schema.labels.join(' & ');
    else {
        let lastValue = schema.labels.pop()
        fileRuleSummary["uploadTypeLabel"] = schema.labels.join(', ')
        fileRuleSummary["uploadTypeLabel"] += ' & ' + lastValue;
    }

    let blankFileList = []

    let sortedFileList = [...fileList].sort((a, b) => {
        const pathA = (a as any).webkitRelativePath.toLowerCase();
        const pathB = (b as any).webkitRelativePath.toLowerCase();
      
        if (pathA < pathB) {
          return -1;
        }
        if (pathA > pathB) {
          return 1;
        }
        return 0;
    })
    // console.log(sortedFileList)

    let TerrainMapperRAWhasGPS = false              // only upload one copy of GPS file for TerrainMapperRAW
    for (var i = 0; i < sortedFileList.length; i++) {
        let isFileValid = false;

        let nameToTest: any = (sortedFileList[i] as any).webkitRelativePath.split('/');
        let sizeToTest: number = (sortedFileList[i] as any).size;
        nameToTest.shift();
        nameToTest = nameToTest.join('/');
        
        schema.properties.flightParts && Object.keys(schema.properties.flightParts.items.properties).some((item) => {
            if (item != "flightPart") {
                // console.log("...1nameToTest, pattern",nameToTest,schema.fileTypes[item].pattern, RegExp(schema.fileTypes[item].pattern, "g").test(nameToTest))
                if (RegExp(schema.fileTypes[item].pattern, "g").test(nameToTest)) {

                    isFileValid = true;
                    let filenameParts: any = nameToTest.split('/').pop();
                    filenameParts = nameToTest.split('.');
                    filenameParts.pop();
                    let flightName = filenameParts.join('.');

                    if (RegExp(schema.properties.flightParts.items.properties.flightPart.pattern, "g").test(flightName) != false) {
                        if (fileRuleSummary["flightParts"][flightName] == null)
                            fileRuleSummary["flightParts"][flightName] = { [item]: [] };
                        else if (fileRuleSummary["flightParts"][flightName][item] == null)
                            fileRuleSummary["flightParts"][flightName][item] = [];

                        fileRuleSummary["flightParts"][flightName][item].push(nameToTest);
                    }
                    return true;//exits the 'some' loop
                } else {
                    return false
                }
            } else {
                return false
            }
        });
        if (schema.properties.anyFileName && schema.properties.anyFileName.properties && isFileValid === false) {//skip if already found
            Object.keys(schema.properties.anyFileName.properties).some((item) => {
                // console.log("...nameToTest, pattern",nameToTest,schema.fileTypes[item].pattern, RegExp(schema.fileTypes[item].pattern, "g").test(nameToTest))
                if (RegExp(schema.fileTypes[item].pattern, "g").test(nameToTest)) {
                    isFileValid = true;

                    if (fileRuleSummary["anyFileName"][item] == null)
                        fileRuleSummary["anyFileName"][item] = [];
                    fileRuleSummary["anyFileName"][item].push(nameToTest);
                    return true;//exits the 'some' loop
                } else {
                    return false
                }
            });
        }
        if(sizeToTest === 0) {
            isFileValid = false             // exclude blank file(0 byte)
            blankFileList.push(nameToTest)
        }

        let relevantPath: any = (sortedFileList[i] as any).webkitRelativePath.split('/');
        relevantPath.shift();
        relevantPath = relevantPath.join('/');

        //some of the logic below can be simplified with more targetted regex's
        if (isFileValid) {
            if ((uploadTypes.includes('TerrainMapperRAW') 
                    // || uploadTypes.includes('TerrainMapperLIDAR') 
                    || uploadTypes.includes('TerrainMapperImagery')) 
                        && RegExp(rulesSchema['fileTypes']['tmGpsDat']['pattern'], "g").test(relevantPath)) {      // take precedence over other rules as there are other generic *.dat files
                if (TerrainMapperRAWhasGPS == false) {
                    fileRuleSummary["validFiles"].push(relevantPath);
                    TerrainMapperRAWhasGPS = true
                    console.log('Found TM GPS file: ', relevantPath)
                } else {
                    fileRuleSummary["invalidFiles"].push(relevantPath);
                }
            // } else if (uploadTypes.includes('TerrainMapperLIDAR')) {
            //     // The validation rules for TerrainMapperLIDAR are all exclusive rules. 
            //     if (RegExp(rulesSchema['fileTypes']['tmRaw']['pattern'], "g").test(relevantPath)
            //             || RegExp(rulesSchema['fileTypes']['tmRawSha1']['pattern'], "g").test(relevantPath)) {
            //         // 'TimeSync.raw' and 'TimeSync.raw.sha1' are exceptions
            //         if (relevantPath.includes('TimeSync')) {
            //             fileRuleSummary["validFiles"].push(relevantPath)
            //         } else {
            //             fileRuleSummary["invalidFiles"].push(relevantPath);
            //         }
            //     } else if (RegExp(rulesSchema['fileTypes']['tmThumbnails']['pattern'], "g").test(relevantPath)
            //             || RegExp(rulesSchema['fileTypes']['tmDarkFrames']['pattern'], "g").test(relevantPath)) {
            //         fileRuleSummary["invalidFiles"].push(relevantPath);
            //     } else {
            //         fileRuleSummary["validFiles"].push(relevantPath);
            //     }
            } else if (uploadTypes.includes('TerrainMapperImagery')) {
                if (RegExp(rulesSchema['fileTypes']['tmDat']['pattern'], "g").test(relevantPath)
                        || RegExp(rulesSchema['fileTypes']['tmDatSha1']['pattern'], "g").test(relevantPath)) {
                    if (RegExp('MM\\d{1,2}\/PRJ\\d{5}').test(relevantPath)   // Upload all imagery files in MM\d{1,2} subfolder except the *_Returns.dat and its sha1 files
                            && !RegExp(rulesSchema['fileTypes']['tmImageryReturnsDat']['pattern'], "gi").test(relevantPath)
                            && !RegExp(rulesSchema['fileTypes']['tmImageryReturnsDatSha1']['pattern'], "gi").test(relevantPath)) {
                        fileRuleSummary["validFiles"].push(relevantPath);
                    } else {
                        fileRuleSummary["invalidFiles"].push(relevantPath);
                    }
                } else {
                    fileRuleSummary["validFiles"].push(relevantPath);
                }
            }
            else {
                fileRuleSummary["validFiles"].push(relevantPath);
            }
        }
        else {
            fileRuleSummary["invalidFiles"].push(relevantPath);
        }
    }
    
    if(blankFileList.length) {
        fileRuleSummary['alertMessages'].push({ name: 'blankFiles', message: blankFileList})
    }

    fileRuleSummary.flightParts = Object.keys(fileRuleSummary.flightParts).map(key => (
        {
            flightPart: key,
            ...fileRuleSummary.flightParts[key]
        }));

    function hex32(val: number) {
        val &= 0xFFFFFFFF;
        var hex = val.toString(16).toUpperCase();
        return ("00000000" + hex).slice(-8);
    }

    /* The labels used below, 7AA59001, 7AA5900F and 7AA59005 were all given to us by Optech */
    let sensorNo = basePath.slice(-3);//optech sensor specific
    console.log('... Sensor No:', sensorNo, ', testing Range and GPS files match...');
    let matchingSensor = sensorEquipment.filter(item => item.Name.slice(-3) === sensorNo)[0];//really need to have a specifc field in sensor equipment for sensor no, not to be confused with serial no.

    if (fileList instanceof FileList) {
        fileList = [...fileList];//changing to array, as array.filter used below in the range and gps validation
    }

    //below only applicable to LiDAR, when checking flightplan kml validity
    if (uploadTypes[0] === "LiDAR") {

        let planData = fileRuleSummary.validFiles.filter(item => RegExp(/PRJ.+\.(KMZ|kmz)/, "g").test(item));

        // let oneFound = false;
        // let incorrectProjects = []

        for (let i = 0; i < planData.length; i++) {
            hasFlightPlan = true;

            console.log('... Scanning flight plan file ' + planData[i] + ' for validity...')
            let _file = fileList.filter(item => item.name === planData[i])[0]

            var new_zip = new JSZip();
            new_zip.loadAsync(_file)
                .then(function (zip) {
                    zip.file("doc.kml").async("string").then(function (result) {
                        let parser = new DOMParser();
                        let xmlDoc = parser.parseFromString(result, "text/xml");
                        if (xmlDoc.getElementsByTagName('parsererror').length > 0)
                            fileRuleSummary.errorMessages.push("Possible corrupt Flight Plan KML, file: '" + _file.name + "', failed validation.");
                        if (result.match(/<B>Line<\/B> = CS/g) != null /*&& result.match(/<B>Line<\/B> = CS/g).length > 0*/) {
                            hasMinOneCS = true;
                        }
                    })
                });
        }
    }

    //below only applicable to LiDAR, when checking range files for serial number
    if (uploadTypes[0] === "LiDAR") {
        let rangeData = fileRuleSummary.validFiles.filter(item => RegExp(/FL\d{6}(_\d{2})?\.range/, "g").test(item));
        for (let i = 0; i < rangeData.length; i++) {
            console.log('... Scanning range file ' + rangeData[i] + ' for serial, gps week and gps time...')
            let _file = fileList.filter(item => item.name === rangeData[i])[0]
            let total64kBlocks = _file.size / 65536;
            let gpsFound, serialFound = false;
            let serialString = undefined;
            for (let i = 0; i < total64kBlocks; i++) {
                let blob64k = _file.slice(i * 65536, (i + 1) * 65536);
                let arrayBuffer64k = await readFileAsyncAsArrayBuffer(blob64k);
                let dv = new DataView(arrayBuffer64k as ArrayBufferLike);
                let label = hex32(dv.getUint32(8))
                if (label === '7AA59001') {
                    let sizeLabel = dv.getInt32(12);
                    for (let j = sizeLabel + 16; j < 65536; j += 4) {
                        let nextLabel = hex32(dv.getInt32(j));
                        if (nextLabel === '7AA5900F') {
                            let GPSTime = dv.getFloat64(j + 16);
                            let GPSWeek = dv.getUint32(j + 28);
                            console.log('... GPS label found, GPSTime:', GPSTime, 'GPSWeek:', GPSWeek)
                            gpsFound = true;
                        }
                        if (nextLabel === '7AA59005') {
                            //serial is 12 bytes after this
                            let decoder = new TextDecoder('utf-8');
                            let bytes = arrayBuffer64k && arrayBuffer64k.slice(j + 16, j + 20)
                            serialString = bytes && decoder.decode(bytes as BufferSource);
                            bytes = arrayBuffer64k && arrayBuffer64k.slice(j + 20, j + 24);
                            let testForNull = new Int8Array(bytes as any);
                            let endIndex = testForNull.length;
                            for (let j = testForNull.length - 1; j >= 0; j--) {
                                if (testForNull[j] === 0) {//the serial will be terminated by 00
                                    endIndex = j;
                                    break;
                                }
                            }
                            let endPart = decoder.decode(bytes as any);
                            endPart = endPart.slice(0, endIndex);
                            serialString += endPart;
                            console.log('... Serial label found:', serialString);
                            serialFound = true;
                        }
                        if (gpsFound && serialFound)
                            break;
                    }
                    if (gpsFound && serialFound)
                        break;
                }
            }
            if (!serialFound) {
                fileRuleSummary.errorMessages.push("Possible corrupt Range file '" + _file.name + "', no laser serial no. could be read");
            }
            else if (matchingSensor === undefined) {
                fileRuleSummary.errorMessages.push("No sensor found with sensor no. '" + sensorNo + "'");
            }
            else if (sensorNo != sensorName.slice(-3)) {
                fileRuleSummary.errorMessages.push("Folder sensor no. '" + sensorNo + "' does not match the expected sensor '" + sensorName + "'");
            }
            else if (serialFound && serialString !== matchingSensor.LaserSerialNo) {
                fileRuleSummary.errorMessages.push("The laser serial no. '" + serialString + "' in Range file '" + _file.name + "', does not match the expected laser serial no. '" + matchingSensor.LaserSerialNo + "'");
            }
        }

        //if has a gps file (*.000...n || *.pos), check that the serial number (read directly from the file) matches the expected sensor serial number
        let gpsData = fileRuleSummary.validFiles.filter(item => RegExp(/FL\d{6}.(\d{3}|pos)/, "g").test(item));
        if (gpsData.length > 0) {
            //should always be the first file
            let _file = fileList.filter(item => item.name === gpsData[0])[0]
            const CHUNK_SIZE = 20000000;//20MB
            let exactParts = Math.ceil(_file.size / CHUNK_SIZE);
            let lastPartSize = _file.size - (Math.floor(_file.size / CHUNK_SIZE) * CHUNK_SIZE);
            let readSerialNo = undefined;
            for (let j = 0; j < exactParts; j++) {
                let start = j * CHUNK_SIZE;
                let end = j === exactParts - 1 ? lastPartSize : start + CHUNK_SIZE;
                let blob = _file.slice(start, end);
                let fileChunk = await readFileAsync(blob as File);
                let serialIndex = (fileChunk as any).indexOf("S/N");
                if (serialIndex !== -1) {
                    readSerialNo = (fileChunk as any).slice(serialIndex, serialIndex + 7);
                    readSerialNo = readSerialNo.slice(3, readSerialNo.length);
                    if (matchingSensor == undefined) {
                        fileRuleSummary.errorMessages.push("No sensor found with sensor no. '" + sensorNo + "'");
                    }
                    else if (readSerialNo !== matchingSensor.POSSerialNo)
                        fileRuleSummary.errorMessages.push("The sensor serial no. '" + readSerialNo + "' in GPS file '" + _file.name + "', does not match the expected sensor serial no. '" + matchingSensor.POSSerialNo + "'");
                    break;
                }
            }
            if (readSerialNo === undefined) {
                fileRuleSummary.errorMessages.push("Possible corrupt GPS file'" + _file.name + "', no sensor serial no. could be read");
            }
        }
        
        console.log(`... Scanning FMS log files...`)
        let fmsLogFileNameList = fileRuleSummary.validFiles.filter(item => RegExp(/^FMS_Flight_Log_([\d\w_-]+).(log|LOG)$/, 'g').test(item))
    
        const CHUNK_SIZE = 8*1024*1024         // 8MB
        const projectIdRegexp = /(PRJ|prj)\d{5}/g
        var fmsLogFilenameListWithNoProjectId: Array<string> = []
        var fmsLogFilenameListWithUnexpectedProjectId: Array<string> = []       // more project ids than database
        var uniqueMatchedProjectIdListGlobal: Array<string> = []
        var isMissingAnyProjectId: boolean|undefined = undefined
        var missingProjectIdList: Array<string> = []
        
        for (const filename of fmsLogFileNameList) {
            let logFile = fileList.filter(item => item.name === filename)[0]
            console.log(`    Parsing FMS log: ${filename}`)
            
            let exactParts = Math.ceil(logFile.size / CHUNK_SIZE)
            let lastPartSize = logFile.size - (Math.floor(logFile.size / CHUNK_SIZE) * CHUNK_SIZE)
            let uniqueMatchedProjectIdList: Array<string> = []
            for(let i=0; i < exactParts; i++) {
                let start = i * CHUNK_SIZE;
                let end = (i === exactParts - 1) ? (start + lastPartSize) : (start + CHUNK_SIZE);
                let blob = logFile.slice(start, end);
                let fileChunk = await readFileAsync(blob as File);
                let matchedProjectIdList = (fileChunk as string).match(projectIdRegexp) || []
                let uniqueMatchedProjectIdListInChunk = matchedProjectIdList.filter((projectId, index, array) => array.indexOf(projectId) === index)
                uniqueMatchedProjectIdListInChunk.forEach(projectId => {
                    if(!uniqueMatchedProjectIdList.includes(projectId)) {
                        uniqueMatchedProjectIdList.push(projectId)
                    }
                })
            }
            // console.log(`uniqueMatchedProjectIdList: ${uniqueMatchedProjectIdList}`)
            if(uniqueMatchedProjectIdList.length === 0) {
                fmsLogFilenameListWithNoProjectId.push(logFile.name)
            }
            if(uniqueMatchedProjectIdList.length && !checkSubsetArrContain(projectIdList, uniqueMatchedProjectIdList)) {
                fmsLogFilenameListWithUnexpectedProjectId.push(logFile.name)
            }
            uniqueMatchedProjectIdList.forEach(projectId => {
                if(!uniqueMatchedProjectIdListGlobal.includes(projectId)) {
                    uniqueMatchedProjectIdListGlobal.push(projectId)
                }
            })
        }
        // console.log(`uniqueMatchedProjectIdListGlobal: ${uniqueMatchedProjectIdListGlobal}`)
        // console.log(`fmsLogFilenameListWithNoProjectId: ${fmsLogFilenameListWithNoProjectId}, fmsLogFilenameListWithUnexpectedProjectId: ${fmsLogFilenameListWithUnexpectedProjectId}`)
        if(uniqueMatchedProjectIdListGlobal.length === 0 || !checkSubsetArrContain(uniqueMatchedProjectIdListGlobal, projectIdList)) {
            // fileRuleSummary["errorMessages"].push("FMS log files don't contain all projects expected for this flight")
            projectIdList.forEach(projectId => {
                if(!uniqueMatchedProjectIdListGlobal.includes(projectId)) {
                    missingProjectIdList.push(projectId)
                }
            })
        }
        if(fmsLogFilenameListWithNoProjectId.length 
                || fmsLogFilenameListWithUnexpectedProjectId.length 
                || missingProjectIdList.length) {
            fileRuleSummary['alertMessages'].push({ name: 'FMSlog', message: { fmsLogFilenameListWithNoProjectId, fmsLogFilenameListWithUnexpectedProjectId, missingProjectIdList }})
        }
    }

    //below only applicable to TerrainMapperLAZ
    if (uploadTypes[0] === "TerrainMapperLAZ") {
        console.log(`... Scanning LAS/LAZ files...`)
        let lazFileNameList = fileRuleSummary.validFiles.filter(item => RegExp(/.(laz|LAZ)$/, 'g').test(item))
    
        const CHUNK_SIZE = 0.3*1024*1024         // 300kB is enough for reading header
        let isRGBNProcessingTemplate = processingTemplate.toUpperCase().split('-').slice(-1)[0].includes('RGBN')
        let filenameListWithoutRGBN = []
        let invalidLazFilenameList = []

        for (const filename of lazFileNameList) {
            let lazFile = fileList.filter(item => item.name === filename)[0]
            // console.log(`    Parsing LAS/LAZ file: ${filename}`)
            let relevantPath: any = (lazFile as any).webkitRelativePath.split('/');
            relevantPath.shift();
            relevantPath = relevantPath.join('/');
            // console.log(relevantPath)
            
            let blob = lazFile.slice(0, CHUNK_SIZE)
            try {
                const header = await lasHeader.readFileObject({input: blob});
                // console.log(header)

                if (isRGBNProcessingTemplate) {
                    let formatId = header['PointDataFormatID'] & 0x3f;
                    // console.log(`Format ID: ${formatId}`)
                    if (formatId !== 8) {
                        filenameListWithoutRGBN.push(relevantPath)
                    }
                }
            } catch(e) {
                invalidLazFilenameList.push(relevantPath)
            }
            
        }
        if (invalidLazFilenameList.length) {
            fileRuleSummary['alertMessages'].push({ name: 'lazHeader', message: { invalidLazFilenameList }})
        }
        if (filenameListWithoutRGBN.length) {
            fileRuleSummary['alertMessages'].push({ name: 'lazRGBN', message: { filenameListWithoutRGBN }})
        }
    }

    // console.log(schema)
    // console.log(fileRuleSummary)
    console.log('... Validating Folder Rules');
    var valid = ajv.validate(schema, fileRuleSummary);
    if (!valid && !isPreexistingUpload) {/// previous uploads aren't evaluated as a whole
        console.log('... Folder validation failed');
        ajv.errors && ajv.errors.forEach(function (error) {
            let errorMessage = "";
            if (error.keyword == "required") {
                errorMessage = error.schema[(error.params as any).missingProperty].errorMessage;
            }
            else if (error.data instanceof Array) {
                error.data = error.data.join(', ');//convert to a string that can be wrapped
                errorMessage = (error.parentSchema as any).errorMessage + ' ' + error.data;
            }
            else {
                errorMessage = (error.parentSchema as any).errorMessage + ' ' + error.data;
            }
            fileRuleSummary["errorMessages"].push(errorMessage);
        });
    }

    console.log('... Folder validation passed, commencing compression and upload');
    // console.log(fileRuleSummary)
    return ({
        fileRuleSummary,
        isMissingFlightplanCS: hasFlightPlan && !hasMinOneCS ? true : false,
    })
}

export default folderValidationHelper